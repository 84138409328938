import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';

const handlePhoneInputChange = (input, phoneObject) => {
  input.setAttribute('value', phoneObject.getNumber());
};

const ensurePlusPrefix = (numberString) => {
  if (numberString.startsWith('+')) {
    return numberString;
  }
  return `+${numberString}`;
};

const initPhoneInput = () => {
  const phoneInput = document.querySelector(
    '[data-behavior="load-phone-input"]'
  );
  if (!phoneInput) return;

  const formattedInput = document.querySelector(
    '[data-behavior="receive-formatted-phone"]'
  );
  const initialCountry = phoneInput.getAttribute('data-defaultcountry');
  const countries = JSON.parse(phoneInput.getAttribute('data-countries'));

  import('intl-tel-input/build/js/utils').then(() => {
    const phoneObject = intlTelInput(phoneInput, {
      allowDropdown: countries.length > 1,
      formatOnDisplay: false,
      initialCountry,
      onlyCountries: countries,
      separateDialCode: true,
    });

    if (formattedInput.value.length > 0) {
      phoneObject.setNumber(ensurePlusPrefix(formattedInput.value));
    }

    phoneInput.addEventListener('keyup', () =>
      handlePhoneInputChange(formattedInput, phoneObject)
    );
    phoneInput.addEventListener('countrychange', () =>
      handlePhoneInputChange(formattedInput, phoneObject)
    );
  });
};

export default initPhoneInput;
