import { fieldName } from '../lib/utils';

function DestroyFields({ fieldPath, question }) {
  const idName = fieldName('event', [...fieldPath, 'id']);
  const destroyName = fieldName('event', [...fieldPath, '_destroy']);

  return (
    <div>
      <input type="hidden" value={question.id} name={idName} />
      <input type="hidden" value={1} name={destroyName} />
    </div>
  );
}

export default DestroyFields;
