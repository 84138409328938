/* eslint-disable no-underscore-dangle */

import findIndex from 'lodash.findindex';

// Utility function to remove a record from a collection of records.
// Depending on whether or not the record was previously persisted, the item
// is either fully removed or only marked to be destroyed.
export const destroy = (records, record) => {
  const index = records.indexOf(record);
  return record.created_at
    ? [
        ...records.slice(0, index),
        { ...record, _destroy: true },
        ...records.slice(index + 1),
      ]
    : [...records.slice(0, index), ...records.slice(index + 1)];
};

// Returns the human readable index of a record from a collection, not counting
// records marked for destroy.
// Returns zero if the record is not part of the collection (e.g. when marked
// for destroy itself).
export const visibleIndexOf = (records, record, indexOffset) => {
  const visibleRecords = records.filter((item) => !item._destroy);
  return findIndex(visibleRecords, record) + indexOffset + 1;
};

// Utility function to build Rails-style field IDs.
export const fieldId = (base, path) => [base, ...path].join('_');

// Utility function to build Rails-style field names.
export const fieldName = (base, path) =>
  base + path.map((segment) => `[${segment}]`).join('');
