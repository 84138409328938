/* eslint-disable no-underscore-dangle */

import Fields from './Fields';
import DestroyFields from '../DestroyFields';

function ClosedQuestion({
  index,
  question,
  destroy,
  getHumanIndex,
  base,
  fieldPath,
  allowRemove,
}) {
  const markedForDestroy = question._destroy;
  const indexedFieldPath = [fieldPath, index];

  return markedForDestroy ? (
    <DestroyFields fieldPath={indexedFieldPath} question={question} />
  ) : (
    <Fields
      base={base}
      fieldPath={indexedFieldPath}
      question={question}
      destroy={destroy}
      getHumanIndex={getHumanIndex}
      allowRemove={allowRemove}
    />
  );
}

ClosedQuestion.defaultProps = {
  question: {},
};

export default ClosedQuestion;
