const appendMapImage = (button) => {
  const modal = button.nextElementSibling;
  if (!modal) return;

  const modalLoadedStatus = modal.getAttribute('data-loaded');
  if (modalLoadedStatus === 'true') return;

  const imageSrc = modal.getAttribute('data-src');
  const modalImg = modal.querySelector('[data-behavior="load-image"]');
  const spinner = modal.querySelector('[data-behavior="spinner"]');
  modalImg.src = imageSrc;
  modalImg.addEventListener('load', () => {
    spinner.remove();
  });
  modal.setAttribute('data-loaded', 'true');
};

const initModalEvents = () => {
  const mapModalButtons = document.querySelectorAll(
    '[data-behavior="open-modal"]'
  );

  mapModalButtons.forEach((button) => {
    button.addEventListener('click', () => appendMapImage(button));
  });
};

export default initModalEvents;
